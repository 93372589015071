import * as React from "react";
import { Helmet } from 'react-helmet';
import '../assets/css/pages/contact-page.css';
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import { addAnimationWithCallback } from "../utils/add-animation-with-callback";
import ReactGA from 'react-ga';

const ContactPage = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const contactFrameRef = React.useRef<HTMLDivElement>(null);
  const nameRef = React.useRef<HTMLInputElement>(null);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const messageRef = React.useRef<HTMLTextAreaElement>(null);
  const confirmationRef = React.useRef<HTMLHeadingElement>(null);

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value)
  }

  const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }

  const handleMessageChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setMessage(event.currentTarget.value);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const nameElement = nameRef.current;
    if (nameElement === null) return;
    if (name.length < 1) {
      nameElement.style.backgroundColor = "#FFF8F8";
      nameElement.placeholder = "Please add your name.";
      return;
    }
    nameElement.style.backgroundColor = "#FFF";
    nameElement.placeholder = "";

    const emailElement = emailRef.current;
    if (emailElement === null) return;
    if (email.length < 1) {
      emailElement.style.backgroundColor = "#FFF8F8";
      emailElement.placeholder = "Please add your email.";
      return;
    }
    emailElement.style.backgroundColor = "#FFF";
    emailElement.placeholder = "";

    const re = /\S+@\S+\.\S+/;
    if (re.test(email) == false) {
      emailElement.style.backgroundColor = "#FFF8F8";
      setEmail('');
      emailElement.placeholder = "Please add a valid email.";
      return;
    }
    emailElement.style.backgroundColor = "#FFF";

    const messageElement = messageRef.current;
    if (messageElement === null) return;
    if (message.length < 1) {
      messageElement.style.backgroundColor = "#FFF8F8";
      messageElement.placeholder = "Please add your message.";
      return;
    }
    messageElement.style.backgroundColor = "#FFF";
    messageElement.placeholder = "";

    const contactFrameElement = contactFrameRef.current;
    if (contactFrameElement === null) return;
    
	addAnimationWithCallback('send-mail-animate', contactFrameElement, async () => {
      const formData = new FormData();
      formData.append("contact-name", name);
      formData.append("contact-email", email);
      formData.append("contact-message", message);

      const response = await fetch('https://pixelworkssoftware.com/scripts/contact-form.php', {
        method: 'POST',
        body: formData
       });

      const confirmationElement = confirmationRef.current;
      if (confirmationElement === null) return;

      confirmationElement.innerHTML = await response.text();
      confirmationElement.style.visibility = 'visible';
    });
  }
  
  
  React.useEffect(() => {
  	ReactGA.initialize('UA-88504612-1');
  	ReactGA.pageview("/contact");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Pixel Works Software: Contact</title>
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.CONTACT} />
      <main>
        <form id="contact-form" onSubmit={handleSubmit} method="post">
          <div ref={contactFrameRef} id="contact-form__frame">
            <div className="contact-form__item">
              <h3 className="contact-form__title">Contact Us</h3>
              <p className="contact-form__subtitle">Please fill this form to get in touch.</p>
            </div>  
            <div className="contact-form__item">
              <h4 className="contact-form__item__title">Full Name <span className="red">*</span></h4>
              <input ref={nameRef} className="contact-form__input" type="text" value={name} onChange={handleNameChange} name="contact-name"></input>
            </div>
            <div className="contact-form__item">
              <h4 className="contact-form__item__title">Email <span className="red">*</span></h4>
              <input ref={emailRef} className="contact-form__input" type="text" value={email} onChange={handleEmailChange} name="contact-email"></input>
            </div>
            <div className="contact-form__item">
              <h4 className="contact-form__item-title">Message <span className="red">*</span></h4>
              <textarea ref={messageRef} className="contact-form__textarea" value={message} onChange={handleMessageChange} name="contact-message"></textarea>
            </div>
            <input className="contact-form__submit-button" type="submit" value="SUBMIT" />
          </div>
        </form>
        <h3 ref={confirmationRef} id="contact-form__confirmation"></h3>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default ContactPage;